import {
  computed,
  nextTick,
  ref,
  watch,
} from 'vue'

import { useAjax } from '@composables/useAjax.js'
import { useI18n } from '@shared/i18n.js'
import { generateUrl } from '@shared/util.js'

const cachePrefectures = ref({ rent: [], sale: [] })

export const usePropertySearch = (args = {}) => {
  const { t, tm } = useI18n()

  const bedrooms = ref(null)
  const bedroomsFrom = ref(null)
  const city = ref(null)
  const cities = ref([])
  const location = ref(null)
  const prefectureCities = ref([])
  const priceFrom = ref('')
  const priceTo = ref('')
  const propertyType = ref([])
  const railways = ref([])
  const stations = ref([])
  const type = ref('sale')

  const Modes = { LIST: 1, MAP: 2 }
  const enumBedrooms = tm('activerecord.enums.property.bedrooms')
  const locale = document.querySelector('html').getAttribute('lang')
  const url = new URL(window.location.href)

  const STORAGE_KEY_VIEW_MODE = 'properties_mode'

  /**
   * 表示モードをクリアする
   */
  const clearViewMode = () => {
    if (!document.referrer.startsWith(window.location.origin)) {
      sessionStorage.removeItem(STORAGE_KEY_VIEW_MODE)
    }
  }

  /**
   * 都道府県の一覧取得
   */
  const getPrefectures = async () => {
    const url = generateUrl('prefectures')
      url.searchParams.append('u', [window.app.common.cacheKey.sale, window.app.common.cacheKey.rent].join('-'))
    const { callGet } = useAjax()
    cachePrefectures.value = await callGet(url)

    if (args.afterGetPrefectures) {
      nextTick(() => args.afterGetPrefectures())
    }
  }

  /**
   * 表示モードを取得する
   */
  const getViewMode = () => {
    const json = sessionStorage.getItem(STORAGE_KEY_VIEW_MODE)
    if (!json) {
      return null
    }
    const data = JSON.parse(json)
    return parseInt(data.mode)
  }

  /**
   * 金額に関するラベル。簡体字・繁体字の場合に調整する
   */
  const labelPrice = computed(() => (key) => {
    const text = t(key)
    if ((locale !== 'zh-CN' && locale !== 'zh-TW') || type.value === 'sale') {
      return text
    }
    return text.replace(new RegExp(t('c.price_rent.from'), "g"), t('c.price_rent.to'))
  })

  /**
   * 間取りの選択肢
   */
  const optionsBedrooms = Object.keys(enumBedrooms).filter((key) => key < 100)
                                .map((key) => ({ value: key, text: enumBedrooms[key] }))
                                .sort((a, b) => parseInt(a.value) - parseInt(b.value))

  /**
   * 都道府県の選択肢
   */
  const optionsPrefectures = computed(() => cachePrefectures.value[type.value])

  /**
   * 価格の選択肢（売買・賃貸で切り替え）
   */
  const optionsPrice = computed(() => {
    return {
      rent: [10000, 20000, 30000, 40000, 50000, 60000, 70000, 80000, 90000, 100000, 150000, 200000, 300000, 400000, 500000, 600000, 700000, 800000, 900000, 1000000, 1500000, 2000000],
      sale: [5000000, 10000000, 20000000, 30000000, 40000000, 50000000, 80000000, 100000000, 300000000, 500000000, 800000000, 1000000000]
    }[type.value]
  })

  /**
   * 物件タイプの選択肢
   */
  const optionsPropertyTypes = computed(() => {
    const propertyTypes = window.app.common.propertyTypes
    const keys = {
      rent: [1, 2, 12, 5, 4, 7, 8, -1],
      sale: [1, 2, 6, 10, 11, 3, 12, -1],
    }[type.value]

    return keys.map((key) => ({
      value: propertyTypes[key] ? propertyTypes[key].replace(/_/, '-') : key,
      text: t(`activerecord.enums.property.property_type.${key}`)
    }))
  })

  /**
   * 物件一覧のURL基盤部分
   */
  const propertiesBaseUrl = computed(() => {
    const format = ":property_type-for-:type/:location"
    const propertyTypes = propertyType.value ? propertyType.value.filter((v) => v != 'property') : [] // なぜかスマホ版の時に入ってくる？
    const allPropertyTypes = propertyTypes.length == 0 || propertyTypes.includes(-1)
    const path = format.replace(":type", type.value)
                       .replace(":property_type", !allPropertyTypes && propertyTypes.length == 1 ? propertyTypes[0] : 'property')
                       .replace(":location", location.value || '')
    return generateUrl(path, '')
  })

  /**
   * 市区町村の監視
   */
  const observePrefectureCities = () => {
    const { callGet } = useAjax()

    const fetch = async () => {
      const url = generateUrl(`prefecture_cities/${type.value}/${location.value}`)
      url.searchParams.append('u', window.app.common.cacheKey[type.value])
      const response = await callGet(url)
      prefectureCities.value = response.cities
    }

    watch(location, (val) => {
      city.value = null
      if (!val) {
        prefectureCities.value = []
      }
      else {
        fetch()
      }
    })

    watch(type, () => {
      city.value = null
      fetch()
    })

    if (location.value) {
      fetch()
    }
  }

  /**
   * 物件一覧のURL生成
   */
  const propertiesUrl = () => {
    const url = propertiesBaseUrl.value
    const propertyTypes = propertyType.value ? propertyType.value.filter((v) => v != 'property') : [] // なぜかスマホ版の時に入ってくる？
    const allPropertyTypes = propertyTypes.length == 0 || propertyTypes.includes(-1)

    // 検索パラメータ
    if (!allPropertyTypes && propertyTypes.length > 1) {
      propertyTypes.forEach((v) => url.searchParams.append('property_types[]', v))
    }
    if (bedrooms.value && Array.isArray(bedrooms.value)) {
      bedrooms.value.forEach((v) => url.searchParams.append('bedrooms[]', v))
    }
    if (bedroomsFrom.value && bedroomsFrom.value != '') {
      url.searchParams.append('bedrooms_from', bedroomsFrom.value)
    }
    if (priceFrom.value && priceFrom.value != '') {
      url.searchParams.append('price_from', priceFrom.value)
    }
    if (priceTo.value && priceTo.value != '') {
      url.searchParams.append('price_to', priceTo.value)
    }
    if (city.value) {
      url.pathname = `${url.pathname}/${city.value}`
    }
    else if (cities.value && cities.value.length > 0) {
      url.searchParams.append('cities', cities.value.join(','))
    }
    if (stations.value && stations.value.length > 0) {
      url.searchParams.append('stations', stations.value.join(','))
    }
    else if (railways.value && railways.value.length > 0) {
      url.searchParams.append('railways', railways.value.join(','))
    }
    if (window.location.href.match(/m=t/)) {
      url.searchParams.delete('m')
      url.searchParams.append('m', 't')
    }

    return url.toString()
  }

  /**
   * 表示モードの設定
   */
  const saveViewMode = (mode) => {
    sessionStorage.setItem(STORAGE_KEY_VIEW_MODE, JSON.stringify({
      mode,
      url: url.pathname // 使わなくなったけど、一応残しておく
    }))
  }

  /**
   * 表示モードの切替
   */
  const switchViewMode = (mode) => {
    const value = mode ? mode : (getViewMode() === Modes.LIST ? Modes.MAP : Modes.LIST)
    url.searchParams.delete('m')
    if (value === Modes.LIST) {
      url.searchParams.append('m', 'f')
    }
    saveViewMode(value)
    if (url.toString() === window.location.href) {
      window.location.reload()
    }
    else {
      window.location.href = url.toString()
    }
  }

  getPrefectures()

  return {
    Modes,
    // variables
    bedrooms,
    bedroomsFrom,
    cities,
    city,
    location,
    prefectureCities,
    priceFrom,
    priceTo,
    propertyType,
    railways,
    stations,
    type,
    // computed
    labelPrice,
    optionsBedrooms,
    optionsPrice,
    optionsPrefectures,
    optionsPropertyTypes,
    propertiesBaseUrl,
    // methods
    clearViewMode,
    getViewMode,
    observePrefectureCities,
    propertiesUrl,
    saveViewMode,
    switchViewMode,
  }
}
